import eruda from "./eruda"
import _pos from "./positions.json"

type RoomPos = number[]
const image_size = [1110, 679]
const pos: Record<string,RoomPos> = _pos // type checking pog

type ScheduleItem = {
    room: string
    marker: string
}

let Schedule: ScheduleItem[] = []
if (process.env.NODE_ENV != "production") {
    Schedule = [
        {
            room: "122B",
            marker: "1"
        }
    ]
    document.getElementById("map_image")?.addEventListener("click", (e) => {
        if (e.shiftKey) {
            const d = document.querySelector("#pins")
            if (d) {
                if (!d.lastChild || d.lastChild.nodeName == "PICTURE") {
                    return
                }
                d.removeChild(d.lastChild)
            }
        } else {
            createPin(e.offsetX,e.offsetY, "T")
            
        }
        
    })
}


function createPin(relx: number, rely: number, marker: string) {
    console.log("Creating pin at "+relx+"px,"+rely+"px")
    const ele = document.createElement("span")
    ele.setAttribute("style", "left: "+relx+"px; "+"top: "+rely+"px;")
    ele.className = "pin"
    ele.innerHTML = marker
    document.getElementById("pins")?.appendChild(ele)
}

function removeLoading() {
    if (document.body.classList.contains("loading")) {
        document.body.classList.remove("loading")
    }
}

function getPosFromRoom(roomName: string): number[] | undefined {
    console.log("Searching for room "+ roomName)
    const r = pos[roomName]
    return r
}

function clearPins() {
    const d = document.querySelector("#pins")
    if (d) {
        for (const childid in d.children) {
            console.log(childid)
            const child = d.children[childid]
            if (child.nodeName != "PICTURE") {
                try {
                    d.removeChild(child)
                } catch {}
            }
        }
    }
}

function renderSchedule() {
    removeLoading()
    clearPins()
    for (const pinid in Schedule) {
        const p = getPosFromRoom(Schedule[pinid].room)
        if (!p) {
            continue
        }
        const [x, y] = p
        createPin(x,y, Schedule[pinid].marker)
    }
    console.log(Schedule.length + " pin(s) loaded")
}

async function main() {
    if (process.env.NODE_ENV != "production") await eruda()
    
    if (Schedule.length) {
        console.log("Schedule preloaded!")
        renderSchedule()
    }
    else {
        window.addEventListener("message", (e) => {
            Schedule = JSON.parse(e.data)
            renderSchedule()
        })
    }
    
}

function showAllRooms() {
    for (const pin of Object.keys(pos)) {
        createPin(pos[pin][0], pos[pin][1], pin)
    }
}

if (process.env.NODE_ENV != "production") {
    // @ts-ignore
    window.debug = {
        createPin,
        showAllRooms,
        renderSchedule,
        getPosFromRoom,
        Schedule,
        clearPins
    }
    console.log("======================\n\nDebug Tips!\n\nUse the window.debug (or just debug) object to call functions from the source code!\n\nin debug mode, clicking create a pin, and shift clicking removes the last pin!\n\n======================")

}

main()